//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-464:_5576,_4572,_416,_4820,_8200,_4208,_5640,_5280;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-464')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-464', "_5576,_4572,_416,_4820,_8200,_4208,_5640,_5280");
        }
      }catch (ex) {
        console.error(ex);
      }